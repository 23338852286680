import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Header from "../components/Header";
import LeftSideBar from "../components/LeftSideBar";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { ThemeProvider } from "@mui/material/styles";
import { useI18next, Trans } from "gatsby-plugin-react-i18next";
import Moment from "react-moment";
import theme from "../config/theme";
import "../styles/layout.css";
import { Container, Box, Card, CardContent, Breadcrumbs, Typography, Chip, Button } from "@mui/material";

const ArticleTemplate = props => {
    const { language, languages, defaultLanguage, siteUrl } = useI18next();
    const { data } = props;
    const [breadcrumbs, setBreadcrumbs] = React.useState([]);

    let urlLanguageCode = "";

    if (language != defaultLanguage) {
        urlLanguageCode = `${language}/`;
    }

    useEffect(() => {
        if (!data.article) {
            return;
        }
        let slug = data.article.slug || "";
        let subcategories = data.subcategories.edges
            ? data.subcategories.edges.map(({ node }) => {
                  return {
                      articles: node.articles,
                      category: node.category,
                      id: node.id,
                      locale: node.locale,
                      name: node.name,
                      order: node.order,
                      slug: node.slug,
                  };
              })
            : [];
        let breadcrumbList = [];

        // find breadcrumbs
        subcategories.forEach(subcategory => {
            if (slug && subcategory.articles.length > 0) {
                subcategory.articles.forEach(article => {
                    if (article.slug == slug) {
                        // clear list first
                        breadcrumbList = [];

                        // first
                        breadcrumbList.push({
                            name: subcategory.category.name,
                            slug: subcategory.category.slug,
                            type: "category",
                        });

                        // second
                        breadcrumbList.push({
                            name: subcategory.name,
                            slug: subcategory.slug,
                            type: "subcategory",
                        });

                        // current
                        breadcrumbList.push({
                            name: data.article.title,
                            slug: data.article.slug,
                            type: "article",
                        });
                    }
                });
            }
        });

        setBreadcrumbs(breadcrumbList);
    }, []);

    const handleClick = (type, slug) => event => {
        if (type == "category") {
            window.open(`${siteUrl}/${urlLanguageCode}category/${slug}`, "_self");
        } else if (type == "subcategory") {
            window.open(`${siteUrl}/${urlLanguageCode}subcategory/${slug}`, "_self");
        } else if (type == "tag") {
            window.open(`${siteUrl}/${urlLanguageCode}tag/${slug}`, "_self");
        }
    };

    console.log(props);
    return (
        <ThemeProvider theme={theme}>
            <Container disableGutters maxWidth={false}>
                <Header props={props} showMenu={true} />
                <Box display={"flex"}>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <LeftSideBar subcategories={props.data.subcategories ? props.data.subcategories.edges : []} slug={props.pageContext.slug} variant={"permanent"} isOpen={true} />
                    </Box>
                    {data.article && (
                        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    {breadcrumbs.map((breadcrumb, index) => (
                                        <Box key={index} component={"span"} sx={{ cursor: "pointer" }} onClick={handleClick(breadcrumb.type, breadcrumb.slug)}>
                                            <Typography style={{ fontSize: 14 }} color="text.primary">
                                                {breadcrumb.name}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Breadcrumbs>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Box component={"span"}>
                                        <Typography style={{ fontSize: 14 }} color="text.primary">
                                            {" "}
                                            <Trans>last_updated</Trans>:<Moment format="YYYY-MM-DD HH:mm:ss">{data.article.updated_at}</Moment>
                                        </Typography>
                                    </Box>
                                </Breadcrumbs>
                            </Box>
                            <Card
                                style={{
                                    boxShadow: "0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 56px rgba(0, 0, 0, 0.04)",
                                }}
                            >
                                <CardContent sx={{ p: 4 }}>
                                    <Typography sx={{ fontSize: "h4.fontSize" }}>{data.article.title}</Typography>
                                    {data.article && data.article.tags && (
                                        <Box display={"flex"} sx={{ pb: 2 }}>
                                            {data.article.tags.map((tag, tagIndex) => (
                                                <Chip key={tagIndex} size={"small"} sx={{ mr: 1 }} label={tag.name} variant="outlined" onClick={handleClick("tag", tag.slug)} />
                                            ))}
                                        </Box>
                                    )}
                                    {data.article && data.article.image && (
                                        <Box display={"flex"} justifyContent={"center"} style={{ width: "100%" }}>
                                            <Box component="img" src={data.article.image.localFile.publicURL} />
                                        </Box>
                                    )}
                                    <ReactMarkdown
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw]}
                                        transformImageUri={uri => (uri.startsWith("http") ? uri : `${process.env.GATSBY_SERVER_URL}${uri}`)}
                                    >
                                        {data.article.content}
                                    </ReactMarkdown>
                                </CardContent>
                            </Card>
                        </Box>
                    )}
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default ArticleTemplate;

export const query = graphql`
    query ($slug: String!, $language: String!, $locale: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        article: strapiArticle(slug: { eq: $slug }, locale: { eq: $locale }) {
            id
            title
            locale
            order
            slug
            content
            updated_at
            subcategory {
                id
                name
                slug
                order
                locale
            }
            image {
                localFile {
                    publicURL
                }
            }
            tags {
                id
                name
                slug
            }
        }
        subcategories: allStrapiSubcategory(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    name
                    slug
                    order
                    locale
                    articles {
                        id
                        title
                        slug
                        order
                        locale
                    }
                    category {
                        id
                        name
                        slug
                        order
                        locale
                    }
                }
            }
        }
    }
`;
